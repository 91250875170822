<template>
  <div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <b-form-group label="Data">
          <date-picker
            style="width: 100%; height: 100%"
            v-model="dataSelecionada"
            valueType="format"
            :format="'DD/MM/YYYY'"
            :clearable="false"
          ></date-picker>
        </b-form-group>
      </div>
      <div class="col-12 col-sm-6">
        <b-form-group label="Caixa Nº">
          <template #label>
            Caixa Nº
            <small
              ><small>[<i>0 para exibir todos</i>]</small></small
            >
          </template>
          <b-spinbutton min="0" v-model="caixaNum" />
        </b-form-group>
      </div>
      <div class="col-12 col-sm-7">
        <select-empresa-clinica
          clinicaInicial
          :comClinica="false"
          @empresa="selectEmpresa"
          :autoBlock="false"
        />
      </div>
      <div class="col-12 col-sm-5">
        <label>Opções</label>
        <b-check
          switch
          v-model="options.paraMedico"
          :velue="true"
          :unchecked-value="false"
          >Para Médico?</b-check
        >
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <i class="fa fa-user-md" /> Médico(s) Disponível(eis)

        <div class="text-center" v-if="loadingMedicos">
          <b-spinner small type="grow" /><br />
          aguarde...
        </div>

        <div class="listaMedicos" v-if="!loadingMedicos">
          <div
            class="opc"
            @click="selectMedico(med)"
            v-for="med in listMedicos"
            :key="`opcMed${med.id}_${Math.random(82372938729832)}`"
            :class="{ selecionado: med.id === options.medicoSelecionado.id }"
          >
            {{ med.nome }}
            <i
              :class="
                `fa fa-${
                  med.id === options.medicoSelecionado.id ? 'check' : 'user-md'
                }`
              "
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-right">
        <b-btn variant="primary" @click="openRelat()" :disabled="loadingPrint">
          <span v-if="loadingPrint">
            <b-spinner small size="sm" /> carregando...
          </span>
          <span v-else> <i class="fa fa-print" /> Abrir </span>
        </b-btn>
        <b-btn variant="secondary" @click="$emit('close')"
          ><i class="fa fa-ban" /> Cancelar</b-btn
        >
      </div>
    </div>
    <relatorio-guias-medicas
      :caixaControle="caixaControle"
      :opts="options"
      v-if="print"
     
      @done="donePrint"
      @close="closePrint"
    />
  </div>
</template>

<script>
import SelectEmpresaClinica from "../../common/SelectEmpresaClinica.vue";
import moment from "moment";
import CaixaLib from "../../../libs/CaixasLib";
import RelatorioGuiasMedicas from "../Relatorios/RelatorioGuiasMedicas.vue";
export default {
  components: {
    SelectEmpresaClinica,
    RelatorioGuiasMedicas,
  },
  props: {},
  mounted() {
    this.buscaCaixaControle();
  },
  data() {
    return {
      caixaNum: 0,
      dataSelecionada: moment().format("DD/MM/YYYY"),
      empresaSelecionada: this.$store.state.empresa,
      print: false,
      loadingPrint: false,
      caixaControle: [],
      loadingMedicos: false,
      listMedicos: [{ id: -1, nome: "Todos" }],
      options: {
        medicoSelecionado: { id: -1, nome: "Todos" },
        paraMedico: true,
      },
    };
  },
  watch: {
    caixaNum: function() {
      this.buscaCaixaControle();
    },
    dataSelecionada: function() {
      this.buscaCaixaControle();
    },
    empresaSelecionada: function() {
      this.buscaCaixaControle();
    },
  },
  computed: {},
  methods: {
    selectMedico(med) {
      this.options.medicoSelecionado = med;
    },
    closePrint() {
      this.print = false;
    },
    donePrint() {
      this.loadingPrint = false;
    },
    selectEmpresa(empresa) {
      this.empresaSelecionada = empresa;
    },
    async buscaCaixaControle() {
      this.loadingMedicos = true;
      this.listMedicos = [{ id: -1, nome: "Todos" }];

      let caixaCont = await CaixaLib.buscarCaixaControle(
        this.empresaSelecionada?.id,
        moment(this.dataSelecionada, "DD/MM/YYYY").format("YYYY-MM-DD"),
        this.caixaNum,
        {
          withDistinticMedics: true,
        }
      );
      // console.log("xx", caixaCont);

      if (caixaCont && caixaCont.length > 0) {
        this.caixaControle = caixaCont;

        caixaCont.forEach(c=>this.listMedicos.push(...c.medicosDistintos))
        //caixaCont.forEach(c=>this.listaMedicos)
      }
      //console.log(this.listMedicos);
      this.loadingMedicos = false;
    },
    async openRelat() {
      this.loadingPrint = true;

      let caixaCont = await CaixaLib.buscarCaixaControle(
        this.empresaSelecionada.id,
        moment(this.dataSelecionada, "DD/MM/YYYY").format("YYYY-MM-DD"),
        this.caixaNum
      );

      if (caixaCont && caixaCont.length > 0) {
        this.caixaControle = caixaCont;
        await this.$forceUpdate();
        await this.$nextTick();
        setTimeout(async () => {
          this.print = true;
          await this.$forceUpdate();
          await this.$nextTick();
        }, 200);
      } else {
        this.loadingPrint = false;
        this.$swal(
          "Atenção",
          "Nenhum caixa aberto com os paramentros informados",
          "error"
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/default.scss";
@mixin selected() {
  background-color: $primary;
  color: white;
  i {
    color: white;
    opacity: 0.6;
  }
}
.listaMedicos {
  display: flex;
  padding: 10px;
  flex-direction: column;
  .opc {
    margin-bottom: 5px;
    flex: 1;
    width: 100%;
    height: 60px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    color: #666;
    i {
      float: right;
      font-size: 40px;
      opacity: 0.1;
    }

    &:hover {
      @include selected;
    }
  }
  .selecionado {
    @include selected;
    i {
      opacity: 1;
    }
  }
}
</style>
