<template>
  <div>
    <div class="row">
      <div class="col-3">
        <label>Data</label>
        <br />
        <span v-if="user.perfil != 'admin'">{{
          data | moment("DD/MM/YYYY")
        }}</span>
        <b-input
          type="date"
          v-model="dataSelecionada"
          v-if="user.perfil == 'admin'"
          :readonly="loading"
        />
      </div>
      <div class="col-6">
        <span v-if="user.perfil != 'admin'">{{ empresa.sigla }}</span>
        <select-empresa-clinica
          v-if="user.perfil == 'admin'"
          clinicaInicial
          :comClinica="false"
          @empresa="selectEmpresa"
          :empresa="empresaSelecionada"
          :readonly="loading"
        />
      </div>
      <div class="col-3">
        <b-btn
          v-if="user.perfil == 'admin'"
          style="margin-top: 25px"
          block
          variant="primary"
          @click="openGerarAcertos"
          ><b-icon-toggles2 /> Gerar Acerto</b-btn
        >
      </div>
    </div>

    <div v-if="loading" class="text-center p-5">
      <b-spinner />
    </div>
    <b-table
      v-if="!loading"
      :items="lista"
      :fields="[
        { key: 'Medico', label: 'Médico' },
        { key: 'qtd', label: 'Qtd.', class: 'text-center' },
        { key: 'vl_custo', label: 'Valor', class: 'text-center' },
        { key: 'total', label: 'Total', class: 'text-center' },
        { key: 'opc', label: '', class: 'text-center' },
      ]"
      :empty-text="`Nenhum acerto encontrado`"
      hover
      :show-empty="!lista || lista.length <= 0"
    >
      <template #cell(vl_custo)="row">
        {{ row.item.vl_custo | currency }}
      </template>
      <template #cell(total)="row">
        {{ row.item.total | currency }}
      </template>
      <template #cell(opc)="row">
        <div>
          <b-btn
            variant="none"
            type="button"
            @click="cancelar(row.item.venda_id)"
          >
            <b-icon-trash />
          </b-btn>
        </div>
      </template>
    </b-table>
    <div
      v-if="(!lista || lista.length <= 0) && user.perfil == 'admin' && !loading"
      class="text-center"
    >
      <b-btn variant="primary" @click="openGerarAcertos"
        ><b-icon-toggles2 /> Gerar Acerto</b-btn
      >
    </div>
    <hr />

    <div class="text-right">
      <b-btn variant="secondary" size="lg" type="button" @click="$emit('close')"
        ><b-icon-door-closed /> Fechar</b-btn
      >
    </div>

    <b-modal
      id="modal-gerar-acertos"
      hide-footer
      title="Gerar Acerto"
      no-close-on-backdrop
      no-close-on-esc
      no-enforce-focus
    >
      <acertos-form
        :empresa="empresa"
        :data="data"
        @close="closeGerarAcertos"
      />
    </b-modal>
  </div>
</template>

<script>
import CaixaLib from "../../libs/CaixasLib";
import VendaLib from "../../libs/VendaLib";
import SelectEmpresaClinica from "../common/SelectEmpresaClinica.vue";
import moment from "moment";
import AcertosForm from "./AcertosForm.vue";
export default {
  components: {
    SelectEmpresaClinica,
    AcertosForm,
  },
  props: {
    data: {
      type: [String, Object],
    },
    empresa: [String, Object],
  },
  mounted() {
    this.empresaSelecionada = this.empresa;
    this.dataSelecionada = moment(this.data, "DD/MM/YYYY").format("YYYY-MM-DD");
    // this.carregarAcertos();
  },
  data() {
    return {
      lista: [],
      loading: false,
      empresaSelecionada: null,
      dataSelecionada: moment(),
    };
  },
  watch: {
    data: function () {
      // console.log(this.data);
      this.dataSelecionada = moment(this.data, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      );
    },
    empresa: function () {
      this.empresaSelecionada = this.empresa;
    },
    dataSelecionada: function () {
      this.carregarAcertos();
    },
    empresaSelecionada: function () {
      this.carregarAcertos();
    },
  },
  computed: {},
  methods: {
    closeGerarAcertos() {
      this.$bvModal.hide("modal-gerar-acertos");
      this.carregarAcertos();
    },
    openGerarAcertos() {
      this.$bvModal.show("modal-gerar-acertos");
    },
    selectEmpresa(emp) {
      this.empresaSelecionada = emp;
    },
    async carregarAcertos() {
      if (!this.loading) {
        this.lista = [];
        this.loading = true;
        this.lista = await CaixaLib.buscarAcertos({
          data: this.dataSelecionada,
          empresa: this.empresaSelecionada,
        });
        // console.log(this.lista)
        this.loading = false;
      }
    },
    async cancelar(venda_id) {
      venda_id;
      this.$swal({
        title: "Tem certeza?",
        text: "Tem certeza que deseja excluir estes acertos?",
        icon: "question",
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
      }).then(async (rep) => {
        // console.log(rep);
        if (rep.isConfirmed) {
          let result = await VendaLib.cancelarVenda(venda_id, "CANCELAMENTO DE ACERTO");
          if (result) {
            this.showToast("success", "Acerto cancelado com sucesso!", 3000);
          } else {
            this.showToast("error", "Erro ao cancelar o acerto!", 3000);
          }
            this.carregarAcertos();

        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>