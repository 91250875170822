<template>
  <div>
    <b-modal
      id="modal-print-relat-lancamentos"
      v-if="printNow"
      hide-footer
      size="lg"
      no-fade
      @hide="close"
    >
      <template #modal-title>
        <b-btn variant="none" @click="print"><b-icon-printer /> Imprimir</b-btn>
      </template>
      <div id="printDiv">
        <print-component
          :printNow="printNow"
          @done="donePrint"
          :titulo="`Lançamentos Caixa`"
          :filtros="
            `Data: ${caixaControle[0].dt_caixa} | Emp.: ${caixaControle[0].empresa}`
          "
          :show="true"
          style="max-width:500px"
        >
          <table class="table table-sm" style="font-size: 9px">
            <thead>
              <tr class="bg-light">
                <th>Venda</th>
                <th>Cliente</th>
                <th class="text-center">Valor</th>
                <th>Usuário</th>
              </tr>
            </thead>
            <tbody
              v-for="venda of lancamentos"
              :key="'relat_lancamentos_' + venda.id"
            >
              <tr>
                <td
                  colspan="9999"
                  style="background-color: #ccc !important"
                ></td>
              </tr>
              <tr class="bg-light mb-3">
                <td class="font-weight-bold">{{ venda.id }}</td>
                <td class="font-weight-bold">{{ venda.cliente }}</td>
                <td class="text-center font-weight-bold">
                  {{ venda.vl_total | currency }}
                </td>
                <td class="font-weight-bold">{{ venda.usuario }}</td>
              </tr>
              <tr>
                <td colspan="989" class="pl-3" style="font-size:8px !important">
                  <div class="row">
                    <div class="col-8">
                      <label>Itens</label>

                      <table
                        class="table tabl-sm table-striped"
                        style="font-size: 8px; margin: 0 auto"
                      >
                        <thead>
                          <tr>
                            <th>Paciente</th>
                            <th>Item</th>
                            <th>Médico/Fornecedor</th>
                            <th>Valor</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="it in venda.items"
                            :key="`item_${venda.id}_${it.vid}`"
                          >
                            <td>{{ it.paciente }}</td>
                            <td>
                              <!-- <div
                              class="text-nowrap text-left"
                              v-if="it.tipo_venda === 'Exame'"
                            >
                              <span v-for="(exame, key) of it.item" :key="key">
                                - {{ exame }} <br />
                              </span>
                            </div>
                            <div v-else> -->
                              {{ it.item }}
                              <!-- </div> -->
                            </td>
                            <td>{{ it.medico }}</td>
                            <td class="text-center">
                              {{ it.vl_total | currency }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-4">
                      <label>Pagamentos</label>
                      <table
                        class="table table-sm table-striped"
                        style="font-size: 8px"
                      >
                        <thead>
                          <tr>
                            <th>Pagamento</th>
                            <th class="text-center">Valor</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="pg in venda.pagamentos"
                            :key="`pgto_venda${venda.id}_${pg.forma}_${pg.id}`"
                          >
                            <td>{{ pg.forma }}</td>
                            <td class="text-center">
                              {{ pg.valor | currency }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <hr />
          <div class="row">
            <div class="col-6">
              <b>Resumo por Pagamento</b>
              <table
                class="table table-striped table-sm"
                style="font-size: 11px"
              >
                <thead>
                  <tr>
                    <th>Forma</th>
                    <th class="text-center">Qtd.</th>
                    <th class="text-right">Valor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="pgt in totalizador.pagamentos"
                    :key="`totalizador_pgt${pgt.forma}`"
                  >
                    <td>{{ pgt.forma }}</td>
                    <td class="text-center">{{ pgt.qtd }}</td>
                    <td class="text-right">{{ pgt.valor | currency }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-6 text-center">
              <b>
                <h4>
                  Total Geral
                  <hr />
                </h4>
                <div class="text-right">
                  <small>Quantidade: {{ lancamentos.length }}</small> <br />
                  Valor:
                  {{
                    lancamentos.reduce((ret, vl) => {
                      ret += vl.vl_total;
                      return ret;
                    }, 0) | currencyWithMask
                  }}
                </div>
              </b>
            </div>
          </div>
        </print-component>
      </div>
    </b-modal>
  </div>
</template>

<script>
import PrintComponent from "../../Print/PrintComponent.vue";
import CaixasLib from "../../../libs/CaixasLib";
import moment from "moment";
import PrinterWindowHelper from "../../../helpers/PrinterWindowHelper";
export default {
  components: {
    PrintComponent
  },
  props: {
    caixaControle: [Array, Object]
  },
  mounted() {
    this.caixaControle[0].dt_caixa = moment(
      this.caixaControle[0].dt_caixa
    ).format("DD/MM/YYYY");
    this.carregarRelatorio();
  },
  data() {
    return {
      lancamentos: [],
      printNow: false,
      totalizador: {
        pagamentos: [],
        qtd: 0,
        vl_total: 0
      }
    };
  },
  watch: {},
  computed: {},
  methods: {
    print() {
      PrinterWindowHelper(document.getElementById("printDiv").innerHTML);
    },
    close() {
      this.$emit("close");
    },
    async donePrint() {
      this.$emit("done");
    },
    async carregarRelatorio() {
      // console.log("start")
      let res = await CaixasLib.relatorioLancamentos(this.caixaControle[0]);
      console.log("rees", res);
      this.lancamentos = res.data;
      // this.lancamentos = res.data.reduce((ret, value) => {
      //   if (
      //     value.tipo_venda === "Exame" &&
      //     value.item != "Solicitação de Exames"
      //   ) {
      //     let idxVenda = ret.findIndex((v) => v.id == value.id);
      //     if (idxVenda > -1) {
      //       if (!Array.isArray(ret[idxVenda].item)) {
      //         ret[idxVenda].item = [value.item];
      //       } else {
      //         ret[idxVenda].item.push(value.item);
      //       }

      //       ret[idxVenda].vl_custo += value.vl_custo;
      //       ret[idxVenda].vl_total = value.pagamentos.reduce((ret, vl) => {
      //         ret += vl.valor;
      //         return ret;
      //       }, 0);
      //     } else {
      //       ret.push({ ...value, item: [value.item] });
      //     }
      //   } else {
      //     ret.push(value);
      //   }
      //   return ret;
      // }, []);
      // console.log("lancamenots: ", this.lancamentos);
      this.totalizador = await this.lancamentos.reduce(
        (ret, vl) => {
          for (let p of vl.pagamentos) {
            let hs = ret.pagamentos.find(x => x.forma === p.forma);
            if (!hs) {
              ret.pagamentos.push({ forma: p.forma, qtd: 1, valor: p.valor });
            } else {
              hs.qtd++;
              hs.valor += p.valor;
            }
            ret.qtd += 1;
            ret.vl_total += p.vl_total;
          }
          return ret;
        },
        { pagamentos: [], qtd: 0, vl_total: 0 }
      );

      this.printNow = true;
      this.$forceUpdate();
      this.$nextTick();
      setTimeout(() => {
        this.$bvModal.show("modal-print-relat-lancamentos");
      }, 200);
    }
  }
};
</script>

<style lang="scss" scoped></style>
