<template>
  <div>
    <b-modal
      id="modal-print-relat-lancamentos"
      v-if="printNow"
      hide-footer
      no-fade
      @hide="close"
      size="lg"
    >
      <div
        v-if="!caixaControle || caixaControle.length <= 0"
        class="text-center"
      >
        Nenhum dado encontrado :(
      </div>
      <template #modal-title>
        <b-btn variant="none" @click="print"><b-icon-printer /> Imprimir</b-btn>
      </template>
      <div id="printDiv">
        <guias
          :show="true"
          :empresas="[
            { id: caixaControle[0].empresa_id, sigla: caixaControle[0].empresa }
          ]"
          :periodo="periodo"
          :para-medico="opts.paraMedico"
          :medicos="
            opts && opts.medicoSelecionado && opts.medicoSelecionado.id !== -1
              ? [opts.medicoSelecionado]
              : []
          "
          @ok="dadosOK"
          @donePrint="donePrint"
          style="max-width:500px !important"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import Guias from "../../Relatorios/Guias/Guias.vue";
// import CaixasLib from "../../../libs/CaixasLib";
import PrinterWindowHelper from "../../../helpers/PrinterWindowHelper";
import moment from "moment";
export default {
  components: {
    // PrintComponent,
    Guias
  },
  props: {
    caixaControle: Array,
    opts: Object
  },
  mounted() {
    // console.log("opts", this.opts, this.caixaControle);
    // this.caixaControle = this.caixaControle.map((cs) => {
    //   return {
    //     ...cs,
    //     dt_caixa: moment(this.caixaControle.dt_caixa).format("DD/MM/YYYY"),
    //   };
    // });
    // console.log(9659,this.caixaControle, this.opts);
    this.$bvModal.show("modal-print-relat-lancamentos");
    this.carregarRelatorio();
  },
  data() {
    return {
      periodo: {
        dt_i: moment(this.caixaControle[0].dt_caixa).format("YYYY-MM-DD"),
        dt_f: moment(this.caixaControle[0].dt_caixa).format("YYYY-MM-DD")
      },
      lancamentos: [],
      its: [],
      printNow: true,
      dt: ""
    };
  },
  watch: {
    caixaControle: {
      deep: true,
      handler() {
        this.periodo.dt_i = this.caixaControle[0].dt_caixa;
        this.periodo.dt_f = this.caixaControle[0].dt_caixa;
        this.dt = moment(this.caixaControle[0].dt_caixa).format("DD/MM/YYYY");
      }
    }
  },
  computed: {},
  methods: {
    print() {
      PrinterWindowHelper(document.getElementById("printDiv").innerHTML);
    },
    close() {
      this.$emit("close");
    },
    async donePrint() {
      this.$emit("done");
    },
    async dadosOK() {
      this.printNow = true;
      this.$forceUpdate();
      this.$nextTick();
      setTimeout(() => {
        this.$bvModal.show("modal-print-relat-lancamentos");
      }, 200);
    },
    async carregarRelatorio() {}
  }
};
</script>

<style lang="scss" scoped></style>
