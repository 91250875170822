<template>
  <div>
    <div class="row">
      <div class="col-6">
        <label>Data</label>
        <b-input type="date" v-model="obj.data" />
      </div>
      <div class="col-6">
        <select-empresa-clinica
          v-if="user.perfil == 'admin'"
          clinicaInicial
          :comClinica="false"
          @empresa="selectEmpresa"
          :empresa="obj.empresa"
        />
      </div>
      <div class="col-12">
        <label>Médico</label>
        {{ this.obj.entidade_id }}
        <select-paciente
          :tipo="['medico']"
          :showBotaoAdd="false"
          :showBotaoRemove="true"
          @paciente="selectMedico"
        />
      </div>
      <div class="col-6 mt-4">
        <label>Quantidade</label>
        <b-input
          type="number"
          v-model="obj.qtd"
          min="1"
          class="text-center"
          max="999"
        />
      </div>
      <div class="col-6 mt-4">
        <label>Valor Custo</label>

        <money
          class="form-control text-center"
          v-model="obj.vl_custo"
          v-bind="moneyMask"
        ></money>
      </div>
      <div class="col-12">
        <hr />
        <div class="text-center">
          <h4>Total: {{ obj.total | currencyWithMask }}</h4>
        </div>
      </div>
      <div class="col-12">
        <botoes-salvar-cancelar @salvar="salvar" @cancelar="$emit('close')" />
      </div>
    </div>
  </div>
</template>

<script>
import SelectEmpresaClinica from "../common/SelectEmpresaClinica.vue";
import moment from "moment";
import SelectPaciente from "../common/SelectPaciente.vue";
import BotoesSalvarCancelar from "../common/BotoesSalvarCancelar.vue";
import EntidadeLib from "../../libs/EntidadesLib";
import CaixaLib from "../../libs/CaixasLib";

export default {
  components: {
    SelectEmpresaClinica,
    SelectPaciente,
    BotoesSalvarCancelar,
  },
  props: {
    data: {
      type: [String, Object],
    },
    empresa: [String, Object],
  },
  mounted() {
    if (this.data)
      this.obj.data = moment(this.data, "DD/MM/YYYY").format("YYYY-MM-DD");
    if (this.empresa) this.obj.empresa_id = this.empresa.id;
  },
  data() {
    return {
      obj: {
        produtoID: 2761,
        pacienteID: 237616,
        entidade_id: null,
        qtd: 1,
        vl_custo: 0,
        total: 0,
        empresa: null,
        data: moment().format("YYYY-MM-DD"),
      },
    };
  },
  watch: {
    data: function () {
      if (this.data)
        this.obj.data = moment(this.data, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    empresa: function () {
      // console.log(this.empresa);
      if (this.empresa) this.obj.empresa_id = this.empresa.id;
    },
    "obj.qtd": function () {
      this.obj.total = this.obj.qtd * this.obj.vl_custo;
    },
    "obj.vl_custo": function () {
      this.obj.total = this.obj.qtd * this.obj.vl_custo;
    },
  },
  computed: {},
  methods: {
    async selectMedico(med) {
      this.obj.entidade_id = med?.id;
      if (med && med.id) {
        let obj = await EntidadeLib.get("medico", "", med.id);
        if (
          obj &&
          obj.entidades &&
          obj.entidades.length > 0 &&
          obj.entidades[0].exames &&
          obj.entidades[0].exames.length > 0
        ) {
          let hasExame = obj.entidades[0].exames.find(
            (x) => x.tipo == "Acerto"
          );
          // console.log("ex", hasExame);
          if (hasExame) {
            // console.log(this.formatFloatToReal(hasExame.valor_custo));
            this.obj.vl_custo = hasExame.valor_custo;
            // this.obj.vl_custo = 1.75;

            // this.obj.vl_custo = "R$ 20,00"
          }
        }
      }
    },
    selectEmpresa(emp) {
      this.obj.empresa = emp;
    },
    async salvar() {
      let err = [];
      if (!this.obj.entidade_id) {
        err.push("Informe um médico!");
      }
      if (!this.obj.empresa) {
        err.push("Selecione uma empresa");
      }
      if (!this.obj.qtd) {
        err.push("Informe ao menos 1 no campo quantidade!");
      }
      if (!this.obj.data) {
        err.push("Informe uma data válida!");
      }

      if (err.length > 0) {
        this.$swal({
          title: "Atenaço",
          html: "Corrija os erros abaixo: <br/>" + err.join("<hr/>"),
          icon: "error",
          type: "error",
        });
        return;
      }

      let res = await CaixaLib.gerarAcertos(this.obj);
      if (!res.success) {
        this.$swal(
          "Erro",
          res.message && res.message != ""
            ? res.message
            : "Houve um erro ao gerar os acertos",
          "error"
        );
      }else{
        this.$swal("Concluído","Acertos gerados com sucesso!", "success").then(()=>{
          this.$emit("close");
        })
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>