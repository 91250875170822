<template>
  <div>
    <!-- {{ caixaControle }} -->
    <!-- {{retirada}} -->
    
    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <caixa-retirada-skeleton />
      </template>
      <h1 class="text-center text-danger" v-if="!!retirada.cancelado_data||!!retirada.cancelado_user_id||!!retirada.cancelado_motivo">CANCELADO</h1>
       <div class="row">
      <div class="col-12">
        <b-form-group label="Motivo">
          <b-input :disabled="!!retirada.id" :state="!!retirada.motivo" v-model="retirada.motivo" />
        </b-form-group>
      </div>
      <div class="col-12">
        <b-form-group label="Valor">
          <money
            :class="['form-control', { 'is-invalid': retirada.valor <= 0 }]"
            v-model="retirada.valor"
             :disabled="!!retirada.id" 
            v-bind="moneyMask"
          />
        </b-form-group>
      </div>
    </div>

    <botoes-salvar-cancelar :saveDisabled="!!retirada.id" @salvar="salvar" @cancelar="close" />
    </b-skeleton-wrapper>
   
  </div>
</template>

<script>
import BotoesSalvarCancelar from "../../../components/common/BotoesSalvarCancelar.vue";
import CaixasLib from "../../../libs/CaixasLib.js";
import CaixaRetiradaSkeleton from "./CaixaRetiradaSkeleton.vue";
export default {
  components: {
    BotoesSalvarCancelar,
    CaixaRetiradaSkeleton
  },
  props: {
    caixaControle: Object,
    id: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    if (this.id) {
      this.carregarRetirada()
    }
  },
  data() {
    return {
      retirada: {
        motivo: "",
        valor: 0.0,
        caixa_id: null
      },
      loading: false
    };
  },
  watch: {},
  computed: {},
  methods: {
    async salvar() {
      try {
        const validar = this.validar();
        // console.log(validar);
        const invalid = validar.filter((v) => !v.status);
        // console.log(invalid);
        if (invalid.length > 0) {
          this.$swal(
            "Erro",
            `Verifique os campos [${invalid.map((x) => x.campo).join(", ")}]`,
            "error"
          );
          return;
        }
        this.retirada.caixa_id = this.caixaControle.id
        let result = await CaixasLib.gerarRetirada(this.retirada);
        if (result.status == 200 || result.status == 201) {
          this.showToast("success", "Retirada gerada com sucesso!");
          this.$emit("retirada");
        }
        console.log("result", result);
      } catch (error) {
        console.log("Erro ao gerar retirada", error);
        this.showToast(
          "error",
          error.response
            ? error.response.data
            : "Ocorreu algum erro inesperado.",
          5000
        );
      }
    },
    validar() {
      let validacao = [];

      if (!this.retirada.valor) {
        validacao.push({ campo: "Valor", status: false });
      }
      if (!this.retirada.motivo) {
        validacao.push({ campo: "Motivo", status: false });
      }

      return validacao;
    },

    close() {
      this.$emit("close");
    },
    async carregarRetirada() {
      this.loading= true
      try {
        this.retirada = await CaixasLib.buscarRetirada(this.id);

      } catch (error) {
        console.log(error);
      }
        this.loading= false
    },
  },
};
</script>

<style lang="scss" scoped></style>
