<template>
  <div class="relatorio">

    <div class="container">
      <div class="row justify-content-center">
        <div class="col">
          <select-clinicas
            v-model="filtros.clinica"
            size="sm"
          ></select-clinicas>
        </div>
        <div class="col">
          <label>Tipo</label>
          <b-form-select
            v-model="filtros.tipo"
            :options="[
              { text: 'Nenhum', value: '' },
              { text: 'Todas', value: 'TODAS' },
              { text: 'À Emitir', value: 'À Emitir' },
              { text: 'Emitidas', value: 'Emitidas' },
            ]"
            size="sm"
          ></b-form-select>
        </div>

        <div class="col">
          <label>Forma de Pagamento</label>
          <b-form-select
            v-model="filtros.formaPagamento"
            :options="[
              { text: 'Nenhum', value: '' },
              { text: 'Todos', value: 'TODOS' },
              { text: 'Cartão / PIX', value: 'CARTAO' },
              { text: 'Outros', value: 'OUTROS' },
            ]"
            size="sm"
          ></b-form-select>
        </div>
      </div>
      <div class="row px-3">
        <div class="col-8 border rounded p-2">
          <div class="d-flex align-items-baseline flex-sm-nowrap flex-wrap">
            <b-form-select
              class="text-center"
              :options="[
                { text: 'Data do Caixa', value: 'CAIXA' },
                { text: 'Data da NFSE', value: 'NFSE' },
              ]"
              v-model="filtros.tipoData"
              size="sm"
            ></b-form-select>
          </div>

          <div class="d-md-flex px-2 mt-2">
            <b-form-group class="mr-md-3 flex-grow-1">
              <label>Inicio</label>
              <b-form-input
                v-model="filtros.dataDe"
                size="sm"
                type="date"
              ></b-form-input>
            </b-form-group>
            <b-form-group class="flex-grow-1">
              <label>Final</label>
              <b-form-input
                v-model="filtros.dataAte"
                size="sm"
                type="date"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="col d-flex align-items-end justify-content-end">
          <b-btn variant="primary" @click="carregar" class="mr-3">
            <div v-if="loading">
              <b-spinner small></b-spinner> Carregando...
            </div>
            <div v-if="!loading"><i class="fas fa-search"></i> Filtrar</div>
          </b-btn>
          <b-btn variant="info">
            <i class="fas fa-print"></i> Relatório Geral</b-btn
          >
        </div>
      </div>
    </div>

    <div class="row mt-3 p-2 font-sm">
      <div class="col">
        <b-table
          sticky-header
          :busy="loading"
          class="shadow"
          :items="relatorio.nfse"
          :fields="campos"
          show-empty
          outlined
          hover
          small
        >
          <template #empty>
            <div class="text-center text-muted d-flex flex-column p-2">
              <div class="justify-content-center py-2 shadow">
                <img
                  width="150px"
                  height="125px"
                  :src="require('@/assets/img/undraw_No_data_re_kwbl.svg')"
                  alt=""
                />
                <p class="mt-2">
                  Nenhum dado encontrado...
                </p>
              </div>
            </div>
          </template>

          <template #cell(vendaData)="{item:{vendaData}}">
            {{ vendaData | moment("DD/MM/YYYY") }}
          </template>
          <template #cell(valorVenda)="{item}">
            {{ item.valorVenda | currencyWithMask }}
          </template>
          <template #cell(nfData)="{item}">
            <div v-if="item.nfId">
              {{ item.nfData | moment("DD/MM/YYYY") }}
            </div>
            <div v-else>
              <small class="text-muted">NFSE não solicitada</small>
            </div>
          </template>
          <template #cell(nfNumero)="{item}">
            <div v-if="item.nfId">
              {{ item.nfNumero }}
            </div>
            <div v-else>
              <small class="text-muted">NFSE não solicitada</small>
            </div>
          </template>
          <template #cell(nfStatus)="{item}">
            <div v-if="item.nfId">
              {{ item.nfStatus }}
            </div>
            <div v-else>
              <small class="text-muted">NFSE não solicitada</small>
            </div>
          </template>
          <template #cell(nfMsg)="{item}">
            <div v-if="item.nfId">
              {{ item.nfMsg }}
            </div>
            <div v-else>
              <small class="text-muted">NFSE não solicitada</small>
            </div>
          </template>
        </b-table>
      </div>
    </div>
    <div class="row">
      <div class="col-12     ">

        <div class=" d-flex justify-content-around   border shadow rounded">
          <div class="d-flex ">
           <b> Total Caixa</b>
            <div class="ml-1"><b-spinner small v-if="loading"></b-spinner></div>
            <div v-if="!loading" class="ml-1">
              {{ relatorio.caixa.vlCaixa | currencyWithMask }}
            </div>
          </div>

          <div class="d-flex ">
            <b>Total Notas</b>
            <div class="ml-1"><b-spinner small v-if="loading"></b-spinner></div>
            <div v-if="!loading" class="ml-1">
              {{ relatorio.caixa.vlNotas | currencyWithMask }}
            </div>
          </div>
          <div class="d-flex ">
            <b>Diferença</b>
            <div class="ml-1"><b-spinner small v-if="loading"></b-spinner></div>
            <div v-if="!loading" class="ml-1">
              {{ relatorio.caixa.vlDiferenca | currencyWithMask }}
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-center">
          <small class="text-muted">
            * contabiliza somente vendas com cartão débito / crédito ou PIX
          </small>
        </div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import NotasFiscaisLib from "../../../libs/NotasFiscaisLib";
import SelectClinicas from "../../common/SelectClinicas.vue";

export default {
  props: {
    clinica: [Number, String],
  },
  components: {
    SelectClinicas,
  },
  data() {
    return {
      relatorio: {
        nfse: [],
        caixa: { vlCaixa: 0.0, vlNotas: 0.0, vlDiferenca: 0.0 },
      },
      campos: [
        { key: "vendaId", label: "Venda", class: "text-center" },
        { key: "clinica", label: "Clínica", class: "text-center" },
        {
          key: "vendaData",
          label: "Data Venda",
          class: "text-center text-nowrap",
          sortable: true,
        },
        {
          key: "nfData",
          label: "Data NFSE",
          class: "text-center text-nowrap",
          sortable: true,
        },
        {
          key: "paciente",
          label: "Paciente",
          class: "text-center text-nowrap",
          sortable: true,
        },
        { key: "valorVenda", label: "Valor", class: "text-center text-nowrap" },
        { key: "nfNumero", label: "NFS-e", class: "text-center text-nowrap" },
        { key: "nfStatus", label: "Situação", class: "text-center" },
        { key: "nfMsg", label: "Mensagem", class: "text-center " },
        // { key: "actions", label: "", class: "text-center" },
      ],
      filtros: {
        clinica: this.clinica,
        tipo: "",
        formaPagamento: "",
        // periodo: true,
        tipoData: "CAIXA",
        dataDe: moment().format("YYYY-MM-DD"),
        dataAte: moment().format("YYYY-MM-DD"),
      },
      loading: false,
    };
  },
  methods: {
    async carregar() {
      this.loading = true;
      try {
        this.relatorio = await NotasFiscaisLib.getNFSERelatorios(this.filtros);
        this.relatorio.nfse = this.relatorio.nfse.map((nf) => {
          return { ...nf, _rowVariant: !nf.nfId ? "danger" : "" };
        });
        // console.log("rel", this.relatorio);
        
      } catch (error) {
        this.showToast("error", error, 3500);
      }
      this.loading = false;
    },
  },
  watch: {
    filtros: {
      deep: true,
      handler() {
        this.relatorio.nfse = [];
        this.relatorio.caixa.vlCaixa = 0;
        this.relatorio.caixa.vlNotas = 0;
        this.relatorio.caixa.vlDiferenca = 0;
      },
    },
  },
};
</script>

<style lang="scss">
.relatorio {
  font-size: 14px;
}
.font-sm {
  font-size: 12px;
}
</style>
