<template>
  <div>
    <!-- {{ caixaControle }} -->
    <!-- {{sangria}} -->

    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <CaixaRetiradaSkeleton />
      </template>
      <h1 class="text-center text-danger" v-if="!!sangria.cancelado_data||!!sangria.cancelado_user_id||!!sangria.cancelado_motivo">CANCELADO</h1>

      <div class="row">
        <div class="col-12">
          <b-form-group label="Motivo">
            <b-input :disabled="!!sangria.id" v-model="sangria.motivo" />
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group label="Valor">
            <money
              :class="['form-control', { 'is-invalid': sangria.valor <= 0 }]"
              v-model="sangria.valor"
              v-bind="moneyMask"
               :disabled="!!sangria.id" 
            />
          </b-form-group>
        </div>
      </div>

      <botoes-salvar-cancelar  :saveDisabled="!!sangria.id" @salvar="salvar" @cancelar="close" />
    </b-skeleton-wrapper>
  </div>
</template>

<script>
import BotoesSalvarCancelar from "../../../components/common/BotoesSalvarCancelar.vue";
import CaixasLib from "../../../libs/CaixasLib.js";
import CaixaRetiradaSkeleton from "./CaixaRetiradaSkeleton.vue";
export default {
  components: {
    BotoesSalvarCancelar,
    CaixaRetiradaSkeleton,
  },
  props: {
    caixaControle: Object,
    id: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    if (this.id) {
      this.carregarSangria();
    }
  },
  data() {
    return {
      sangria: {
        motivo: "",
        valor: 0.0,
        caixa_id: null,
      },
      loading: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    async salvar() {
      try {
        const validar = this.validar();
        // console.log(validar);
        const invalid = validar.filter((v) => !v.status);
        // console.log(invalid);
        if (invalid.length > 0) {
          this.$swal(
            "Erro",
            `Verifique os campos [${invalid.map((x) => x.campo).join(", ")}]`,
            "error"
          );
          return;
        }
        this.sangria.caixa_id = this.caixaControle.id;
        let result = await CaixasLib.gerarSangria(this.sangria);
        if (result.status == 200 || result.status == 201) {
          this.showToast("success", "Sangria gerada com sucesso!");
          this.$emit("sangria");
        }
        console.log("result", result);
      } catch (error) {
        console.log("Erro ao gerar sangria", error);
        this.showToast(
          "error",
          error.response
            ? error.response.data
            : "Ocorreu algum erro inesperado.",
          5000
        );
      }
    },
    validar() {
      let validacao = [];

      if (!this.sangria.valor) {
        validacao.push({ campo: "Valor", status: false });
      }
      //   if (!this.sangria.motivo) {
      //     validacao.push({ campo: "Motivo", status: false });
      //   }

      return validacao;
    },

    close() {
      this.$emit("close");
    },
    async carregarSangria() {
        this.loading = true
      try {
        this.sangria = await CaixasLib.buscarSangria(this.id);
      } catch (error) {
        console.log(error);
      }
      this.loading = false
    },
  },
};
</script>

<style lang="scss" scoped></style>
