<template>
  <div class="container caixa">
    <!-- <barra-crud /> -->
    <!-------------- INFOS ------------->
    <div class="row barra pt-2 pb-3 pb-sm-0">
      <div class="col-12 col-md-6 col-lg-4">
        <select-empresa-clinica
          clinicaInicial
          :comClinica="false"
          @empresa="selectEmpresa"
        />
      </div>
      <div class="col-12 col-sm-4 col-md-3 col-lg-2">
        <label><b-icon-calendar /> Data</label>
        <date-picker
          style="width: 100%; height: 100%"
          v-model="dataSelecionada"
          valueType="format"
          :format="'DD/MM/YYYY'"
          :clearable="false"
        ></date-picker>
      </div>
      <div class="col-12 col-md-3 col-lg-2">
        <label><b-icon-sort-numeric-down /> Nº caixa</label>
        <b-spinbutton v-model="numSelecionado" min="1" max="4" />
      </div>
      <div class="col-12 col-lg-4">
        <label><b-icon-info-circle-fill /> Informações</label>
        <caixa-informacoes
          :empresa="empresaSelecionada"
          :data="dataSelecionada"
          :numCaixa="numSelecionado"
          @caixaControle="setCaixaControle"
          ref="caixaInformacoes"
        />
      </div>
    </div>
    <div class="row">
      <!---------------- BOTOES MENU ------------>
      <div class="col text-left p-0">
        <div v-if="isOpen">
          <b-button :disabled="!isOpen" class="btnCaixa" @click="openVenda"
            ><i class="fa fa-plus-circle" /> <br />
            Venda</b-button
          >
          <!-- <b-button class="btnCaixa"
          ><i class="fa fa-hand-holding-usd" /> <br />Recebimento</b-button
        > -->
          <b-button :disabled="!isOpen" class="btnCaixa" @click="openSangria"
            ><i class="fa fa-wallet" /><br />
            Sangria</b-button
          >
          <b-button :disabled="!isOpen" class="btnCaixa" @click="openRetirada"
            ><i class="fa fa-weight-hanging" /> <br />
            Retirada</b-button
          >
        </div>
        <div v-else class="text-center p-4">
          <h2>
            <b-icon-eye-slash
              style="font-size: 40px !important; opacity: 0.5 !important"
            />
          </h2>
          <br />
          Opções de lançamento indisponíveis no momento. Caixa fechado!
        </div>
      </div>
      <!----------------- FILTROS ------------------->
      <div class="col-8 text-left p-2 filtros pr-5">
        <div class="grupo"><b-icon-filter /> Filtros</div>
        <div class="row mt-3">
          <div class="col-4">
            <label>Usuario</label>
            <b-select
              :options="filtrosLista.usuarios"
              text-field="nome"
              v-model="filtros.usuario"
            />
          </div>
          <div class="col-4">
            <label>Paciente</label>
            <b-select
              :options="filtrosLista.pacientes"
              v-model="filtros.paciente"
            />
          </div>
          <div class="col-4">
            <label>Medico</label>
            <b-select
              :options="filtrosLista.medicos"
              v-model="filtros.medico"
            />
          </div>
          <div class="col-4 mt-2 pr-0">
            <label>Nota Fiscal</label> <br />
            <b-radio-group
              v-model="filtros.nota"
              buttons
              size="sm"
              button-variant="outline-primary"
            >
              <b-radio value="0">Todos</b-radio>
              <b-radio value="1">Pendente</b-radio>
              <b-radio value="2">Emitido</b-radio>
            </b-radio-group>
          </div>
          <div class="col-4 mt-2">
            <label>Especialidade</label>
            <b-select
              :options="filtrosLista.especialidades"
              v-model="filtros.especialidade"
            />
          </div>
          <div class="col-4 mt-2">
            <label>Consulta / Exame / Produto</label>
            <b-select :options="filtrosLista.exames" v-model="filtros.exame" />
          </div>
        </div>
      </div>
    </div>

    <div class="row pr-4">
      <div class="col-12 text-right pr-4">
        <b-radio-group
          button
          button-variant="outline-primary"
          buttons
          v-model="tipoListagem"
        >
          <b-radio value="list"><b-icon-list /> </b-radio>
          <b-radio value="card"><b-icon-card-text /> </b-radio>
        </b-radio-group>
      </div>
    </div>

    <div class="row mt-3 conteudoCx" v-if="caixaControle">
      <div v-if="loading" class="text-center">
        <b-spinner small /> Carregando...
      </div>
    </div>

    <!------------ LANCAMENTOS ------------->
    <div
      class="col mt-5"
      v-if="
        !loading &&
        (!listagem || !listagem.lancamentos || listagem.lancamentos.length <= 0)
      "
    >
      Nenhum Caixa lançado ainda.
      <br />
      {{ dataSelecionada }}
    </div>
    <div
      class="col-12"
      v-if="listagem && listagem.lancamentos && listagem.lancamentos.length > 0"
    >
      <span
        v-for="grupo in listagem.lancamentos"
        :key="`cx_grp${grupo.grupo}_${Math.random(291380219)}`"
      >
        <div class="grupo">{{ grupo.grupo }}</div>
        <div class="row pr-4 pl-4" v-if="tipoListagem == 'card'">
          <div
            v-for="lancamento in grupo.lista"
            :key="`cx_lanc${lancamento.id}_${grupo.grupo}__${Math.random(
              291380219
            )}`"
            class="col-12 col-lg-12 col-xl-6 p-2"
          >
            <div
              class="card"
              :class="{ cancelado: lancamento.cancelado }"
              @click="openDetail(lancamento)"
            >
              <div class="row">
                <div class="col-6">
                  <div class="titulo">
                    <small>
                      <i class="fa fa-user" /> {{ lancamento.cliente }}
                    </small>
                  </div>
                  <div class="titulo">
                    <small>
                      <small>
                        <i class="fab fa-accessible-icon" />
                        {{ lancamento.paciente }}
                      </small>
                    </small>
                    <br />
                    <small v-if="lancamento.item_nome">
                      <i class="fa fa-notes-medical" />
                      {{ lancamento.item_nome }}
                    </small>
                    <small v-if="lancamento.fornecedor">
                      <br v-if="lancamento.item_nome" />

                      <i class="fa fa-user-md" /> {{ lancamento.fornecedor }}
                    </small>
                  </div>
                </div>
                <div class="col-6 text-right">
                  <div class="valor">
                    <h5>{{ lancamento.vl_total | currencyWithMask }}</h5>
                  </div>
                  <small>
                    <i class="fa fa-headset" /> {{ lancamento.usuario }}
                  </small>
                  <div class="valor">
                    <div>
                      <i class="fas fa-file-invoice-dollar"></i> NFSE -
                      {{
                        lancamento.nf ? lancamento.nf.status : "Não solicitada."
                      }}
                    </div>
                  </div>
                </div>

                <div class="col mt-2">
                  <b-btn
                    @click.stop="cancelarVenda(lancamento.id)"
                    :disabled="lancamento.cancelado"
                    size="sm"
                    variant="danger"
                    class="float-left"
                    ><i class="fa fa-ban" /> Canelar</b-btn
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row pr-4 pl-4" v-if="tipoListagem == 'list'">
          <div class="col-12">
            <div
              class="text-center"
              v-if="!grupo.lista || grupo.lista.length <= 0"
            >
              Nenhum lançamento aqui.
            </div>
            <b-table
              v-if="grupo.lista && grupo.lista.length > 0"
              style="font-size: 12px"
              small
              selectable
              :items="grupo.lista"
              :fields="[
                { key: 'id', label: 'Cód.' },
                { key: 'paciente', label: 'Paciente', class: 'text-left' },
                { key: 'item_nome', label: 'Item', class: 'text-left' },
                {
                  key: 'fornecedor',
                  label: 'Fornecedor/Médico',
                  class: 'text-left',
                },
                { key: 'cancelado' },
                { key: 'devolvido' },
                { key: 'usuario', label: 'Usuário', class: 'text-left' },
                { key: 'vl_venda', label: 'Valor', class: 'text-right' },
                { key: 'especialidade' },
              ]"
              hover
              select-mode="single"
              @row-selected="abrirDaLista"
            >
              <template #cell(devolvido)="row">
                <i
                  class="fa fa-undo text-danger"
                  v-if="row.item.devolvido == 1"
                ></i>
              </template>
              <template #cell(paciente)="row">
                <div>
                  {{ row.item.cliente }}
                  <div v-if="row.item.paciente" class="pl-1">
                    <small>
                      <i class="fa fa-user-injured" /> {{ row.item.paciente }}
                    </small>
                  </div>
                </div>
              </template>
              <template #cell(vl_venda)="row">
                {{ row.item.vl_venda | currency }}
              </template>
              <template #cell(cancelado)="row">
                <i :class="row.item.cancelado ? 'fa fa-check' : ''" />
              </template>
              <template #cell(item_nome)="row">
                <small v-if="row.item.items && row.item.items.length > 0">
                  <ul>
                    <li
                      v-for="it in row.item.items"
                      :key="`rowITVnd${row.item.id}_${it}__${Math.random(
                        291380219
                      )}`"
                    >
                      {{ it }}
                    </li>
                  </ul>
                </small>
                <div v-else>{{ row.item.item_nome }}</div>
              </template>
            </b-table>
          </div>
        </div>
      </span>
    </div>
    <!------------DEVOLUCOES-------------------->
    <div
      class="col-12 mt-3"
      v-if="listagem && listagem.devolucoes && listagem.devolucoes.length > 0"
    >
      <div class="grupo">Devoluções</div>
      <div class="row pr-4 pl-4">
        <b-table
          ref="tbDevs"
          :items="listagem.devolucoes"
          :fields="[
            { key: 'id', label: 'Código' },
            { key: 'venda_id', label: 'Venda' },
            { key: 'Cliente', label: 'Cliente' },
            { key: 'vl_estorno', label: 'Valor' },
            { key: 'usuario', label: 'Usuário' },
          ]"
          hover
          selectable
          @row-selected="openDevolucao"
        >
          <template #cell(venda_id)="row">
            {{ row.item.venda_id }}
            <br />
            {{ row.item.dt_venda | moment("DD/MM/YYYY") }}
          </template>
          <template #cell(vl_estorno)="row">
            {{ row.item.vl_estorno | currency }}
          </template>
        </b-table>
      </div>
    </div>
    <!--------RETIRADAS------->
    <div
      class="col-12 mt-3"
      v-if="listagem && listagem.retiradas && listagem.retiradas.length > 0"
    >
      <div class="grupo">Retiradas / Saídas</div>
      <div class="row pr-4 pl-4">
        <div
          v-for="lancamento in listagem.retiradas"
          :key="`cx_retir${lancamento.id}`"
          class="col-12 col-sm-6 col-lg-4 p-2"
          :class="{
            cancelado:
              !!lancamento.cancelado_data ||
              !!lancamento.cancelado_user_id ||
              !!lancamento.cancelado_motivo,
          }"
        >
          <div class="cardWithOptions card noCursor">
            <div class="titulo">
              <i class="fa fa-money-bill-wave" /> {{ lancamento.motivo }}
            </div>
            <div class="valor">
              {{ lancamento.valor | currencyWithMask }}
            </div>
          </div>
          <div class="opcoes">
            <b-btn
              @click="editar(lancamento.tipo, lancamento.id)"
              size="sm"
              variant="warning"
              ><i class="fa fa-edit" /> Editar</b-btn
            >
            <b-btn
              @click="cancelarRetirada(lancamento.id)"
              size="sm"
              variant="danger"
              ><i class="fa fa-ban" /> Canelar</b-btn
            >
          </div>
        </div>
      </div>
    </div>

    <!------------MODAIS---------------->
    <b-modal id="modal-caixa-retirada" title="Retirada de Caixa" hide-footer>
      <caixa-retirada
        @close="closeRetirada"
        @retirada="onRetirada"
        :caixaControle="caixaControle"
        :id="id_retirada"
      />
    </b-modal>
    <b-modal id="modal-caixa-sangria" title="Sangria" hide-footer>
      <caixa-sangria
        @close="closeSangria"
        @sangria="onSangria"
        :caixaControle="caixaControle"
        :id="id_sangria"
      />
    </b-modal>
    <b-modal
      id="modal-caixa-venda"
      title="Venda"
      hide-footer
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
    >
      <venda
        ref="vendaObj"
        :caixa="caixaControle"
        @cancelar="closeVenda"
        @concluido="openVendaConcluida"
      />
    </b-modal>
    <b-modal
      id="modal-venda-detail"
      size="lg"
      title="Detalhes de venda"
      hide-footer
      no-enforce-focus
      @hide="closeVendaDetail"
    >
      <venda-detail
        @venda:nfse="onNfse($event)"
        :vendaId="vendaEdit"
        @close="closeVendaDetail"
        ref="vendaDetails"
        @cancelarVenda="cancelarVenda($event)"
      />
    </b-modal>
    <b-modal
      id="modal-devolucao-detail-cx"
      size="lg"
      hide-footer
      title="Devolução"
    >
      <venda-devolucao-detail
        :devolucaoId="devolucaoOpenId"
      ></venda-devolucao-detail>
    </b-modal>
  </div>
</template>

<script>
// import BarraCrud from "../../../components/common/BarraCrud.vue";
import SelectEmpresaClinica from "../../../components/common/SelectEmpresaClinica.vue";
import moment from "moment";
import CaixaInformacoes from "../../../components/Caixas/CaixaInformacoes.vue";
import CaixaRetirada from "./CaixaRetirada.vue";
import CaixaSangria from "./CaixaSangria.vue";
import Venda from "../../../components/Caixas/Vendas/Venda.vue";
import CaixasLib from "../../../libs/CaixasLib";
import VendaDetail from "../../../components/Caixas/Vendas/VendaDetail.vue";
import VendaLib from "../../../libs/VendaLib";
// import VendaDevolucaoDetail from ;

export default {
  components: {
    SelectEmpresaClinica,
    // BarraCrud,
    CaixaInformacoes,
    CaixaRetirada,
    Venda,
    CaixaSangria,
    VendaDetail,
    VendaDevolucaoDetail: () =>
      import("../../../components/Caixas/Vendas/VendaDevolucaoDetail.vue"),
  },
  props: {},
  mounted() {
    let emp = localStorage.getItem("empresa_caixa");
    // console.log('empresa encontrada',emp);
    if (emp && emp != "") {
      try {
        this.selectEmpresa(JSON.parse(emp));
      } catch {
        console.log("erro");
      }
    }
  },
  data() {
    return {
      dataSelecionada: moment().format("DD/MM/YYYY"),
      empresaSelecionada: null,
      numSelecionado: 1,
      caixaControle: null,
      items: [],
      id_retirada: null,
      id_sangria: null,
      listagem: [],
      listagemRaw: [],
      loading: false,
      vendaEdit: null,
      filtrosLista: {
        usuarios: [],
        pacientes: [],
        medicos: [],
        especialidades: [],
        exames: [],
      },
      filtros: {
        usuario: "Todos",
        paciente: "Todos",
        medico: "Todos",
        especialidade: "Todos",
        exame: "Todos",
        nota: 0,
      },
      tipoListagem: "list",
      devolucaoOpenId: 0,
    };
  },
  watch: {
    dataSelecionada: {
      handler() {
        this.listagem = [];
      },
    },
    filtros: {
      deep: true,
      handler() {
        this.Filtrar();
      },
    },
  },
  computed: {
    isOpen() {
      return (
        this.caixaControle &&
        this.caixaControle.id > 0 &&
        !this.caixaControle.dt_fechamento
      );
    },
  },
  methods: {
    openDevolucao(items) {
      console.log(items);
      if (items && items.length > 0) {
        this.devolucaoOpenId = items[0].id;
        console.log("dev", this.devolucaoOpenId);
        this.$bvModal.show("modal-devolucao-detail-cx");
        this.$refs["tbDevs"].clearSelected();
      }
    },
    abrirDaLista(items) {
      this.openDetail(items[0]);
    },
    onNfse(nfse) {
      nfse;
    },
    editar(tipo, id) {
      // console.log('editar', tipo, id);
      if (tipo === "sangria") {
        this.editarSangria(id);
      } else if (tipo === "retirada") {
        this.editarRetirada(id);
      }
    },
    openDetail(venda) {
      if (venda) {
        this.vendaEdit = venda.id;
        this.$bvModal.show("modal-venda-detail");
      }
    },
    async cancelarVenda(id) {
      try {
        let cancelPix = false;

        let fnd = this.listagem.lancamentos.find(
          (a) => a.lista.find((x) => x.id == id) != null
        );
        let vn = fnd.lista.find((q) => q.id === id);
        if (vn && vn.pagamentos) {
          let px = vn.pagamentos.filter((x) => x.forma === "PIX");
          if (px && px.length > 0) {
            cancelPix = await this.$swal
              .fire({
                title: "Esta venda possui PIX pagos!",
                html:
                  "Deseja também realizar a devolução dos PIX abaixo listados? <hr/><small>" +
                  px
                    .map((a) => {
                      if (a.dados_pagamento && a.dados_pagamento != "") {
                        let ob = JSON.parse(a.dados_pagamento);
                        console.log(ob);
                        return `<b>Identificador:</b> ${ob.pix.txId} <br/> 
                          <b>Valor: </b> ${this.formatFloatToReal(
                            ob.pix.valor
                          )}`;
                      } else {
                        return "--";
                      }
                    })
                    .join("<hr />") +
                  "</small>",
                icon: "question",
                showCancelButton: true,
                cancelButtonText: "Não devolver o PIX e cancelar a venda.",
                confirmButtonText: "Devolver PIX e cancelar a venda!",
              })
              .then((x) => {
                return x.isConfirmed;
              });
            console.log("Resposta pix", cancelPix);
          }
          // return;
        }
        console.log(cancelPix);

        // await this.$swal({
        //   title: "Tem certerza que deseja cancelar a venda?",
        //   text: "Isso não poderá ser desfeito!",
        //   icon: "warning",
        //   showCancelButton: true,
        //   cancelButtonText: "Não",
        //   confirmButtonText: "Sim",
        // }).then(async (re) => {
        // if (re.isConfirmed) {
        const { value: observacao } = await this.$swal.fire({
          title: "Motivo do cancelamento",
          html: `<b>Isso irá cancelar todos os itens relacioandos a essa veda!!</b> <i>(Fidelizações, Notas fiscais, etc...)</i>`,
          input: "text",
          icon: "warning",
          inputLabel: "Motivo",
          inputPlaceholder: "Digite o motivo",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Continuar",
          inputValidator: (value) => {
            if (!value) {
              return "Por favor, informe o motivo do cancelamento.";
            }
          },
        });
        if (!observacao) return;

        let result = await VendaLib.cancelarVenda(id, observacao, cancelPix);
        if (result) {
          this.showToast("success", "Venda cancelada com sucesso!", 3000);
          this.closeVendaDetail();
        } else {
          this.showToast("error", "Erro ao cancelar a venda!", 3000);
        }
        //   }
        // });
      } catch (error) {
        this.showToast(
          "error",
          "Erro ao cancelar a venda!\n" + error.message,
          3000
        );
      }
    },
    async closeVendaDetail() {
      this.vendaEdit = null;
      this.$bvModal.hide("modal-venda-detail");
      await this.carregarListagem();
    },
    async carregarListagem() {
      this.listagemRaw = [];

      // console.log(1, this.caixaControle);
      this.loading = true;
      if (this.caixaControle) {
        this.listagemRaw = await CaixasLib.listagemCaixa({
          data: this.dataSelecionada
            ? moment(this.dataSelecionada, "DD/MM/YYYY").format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD"),
          empresa_id: this.empresaSelecionada?.id,
          caixa_num: this.numSelecionado,
        });
        this.listagemRaw.lancamentos = this.listagemRaw.lancamentos.sort(
          (a, b) => {
            return a.grupo.trim().localeCompare(b.grupo.trim());
          }
        );

        this.filtrosLista = await this.listagemRaw.lancamentos.reduce(
          (ret, vl) => {
            for (let li of vl.lista) {
              if (!ret.usuarios.some((x) => x === li.usuario)) {
                ret.usuarios.push(li.usuario);
              }
              if (!ret.pacientes.some((x) => x === li.paciente)) {
                ret.pacientes.push(li.paciente);
              }
              if (!ret.medicos.some((x) => x === li.fornecedor)) {
                ret.medicos.push(li.fornecedor);
              }
              if (!ret.exames.some((x) => x === li.item_nome)) {
                ret.exames.push(li.item_nome);
              }
            }
            if (!ret.especialidades.some((x) => x === vl.grupo)) {
              ret.especialidades.push(vl.grupo);
            }
            return ret;
          },
          {
            usuarios: ["Todos"],
            pacientes: ["Todos"],
            medicos: ["Todos"],
            especialidades: ["Todos"],
            exames: ["Todos"],
          }
        );
      }
      this.Filtrar();
      this.loading = false;
      // console.log(2, this.listagem);
    },
    async Filtrar() {
      if (this.listagemRaw.lancamentos) {
        let list = JSON.parse(JSON.stringify(this.listagemRaw));
        list.lancamentos = list.lancamentos.filter((x) =>
          this.filtros.especialidade && this.filtros.especialidade != "Todos"
            ? x.grupo === this.filtros.especialidade
            : 1 === 1
        );
        for (let g of list.lancamentos) {
          g.lista = g.lista
            .filter(
              (x) =>
                (this.filtros.paciente && this.filtros.paciente != "Todos"
                  ? x.paciente === this.filtros.paciente
                  : 1 === 1) &&
                (this.filtros.usuario && this.filtros.usuario != "Todos"
                  ? x.usuario === this.filtros.usuario
                  : 1 === 1) &&
                (this.filtros.medico && this.filtros.medico != "Todos"
                  ? x.fornecedor === this.filtros.medico
                  : 1 === 1) &&
                (this.filtros.exame && this.filtros.exame != "Todos"
                  ? x.item_nome === this.filtros.exame
                  : 1 === 1) &&
                (this.filtros.nota && this.filtros.nota != 0
                  ? this.filtros.nota == 1
                    ? x.nf == null && x.comCartao > 0
                    : x.nf != null
                  : 1 === 1)
            )
            .map((it) => {
              return {
                ...it,
                _rowVariant: it.cancelado
                  ? "danger"
                  : it.devolvido
                  ? "warning"
                  : "",
              };
            });
        }
        // for (let g of list) {
        //   console.log(g);
        //   // g.lista = await g.lista.reduce((ret,vl)=>{
        //   //   let has = ret.find(x=>x.)

        //   // },[])
        // }

        for (let g of list.lancamentos) {
          g.lista = await g.lista.reduce((ret, vl) => {
            let has = ret.find((x) => x.id === vl.id);
            if (!has) {
              ret.push({ ...vl, items: [vl.item_nome] });
            } else {
              has.items.push(vl.item_nome);
            }
            return ret;
          }, []);
        }

        //  console.log("listaaa", list);
        this.listagem = list;
      }
    },
    selectEmpresa(empresa) {
      this.listagem = [];
      if (empresa) {
        // console.log(JSON.parse(empresa));
        empresa.usuario_change = this.user;
        let hasChange = true;
        let emp = localStorage.getItem("empresa_caixa");

        if (emp && emp != "") {
          try {
            emp = JSON.parse(emp);
            if (emp.id == empresa.id) {
              hasChange = false;
            }
          } catch {
            console.log("erro");
          }
        }
        if (hasChange) {
          this.$swal({
            title: "Deseja alterar o caixa do computador local?",
            text: "Ao alterar o caixa qualquer usuário irá abrir no mesmo caixa alterado!",
            icon: "question",
            showCancelButton: true,
            showConfirmButtom: true,
            confirmButtonText: "Alterar!",
            cancelButtonText: "Não",
          }).then(async (x) => {
            if (x.isConfirmed) {
              localStorage.setItem("empresa_caixa", JSON.stringify(empresa));
            }
          });
        }
      }
      this.empresaSelecionada = empresa;
      // this.$store.state.
      this.user.clinica_id = empresa.id;
    },
    setCaixaControle(caixaControle) {
      this.caixaControle = caixaControle;
      this.carregarListagem();
    },
    async onRetirada() {
      await this.$refs.caixaInformacoes.buscarCaixa();
      this.closeRetirada();
    },
    openRetirada() {
      this.id_retirada = null;
      this.$bvModal.show("modal-caixa-retirada");
    },
    closeRetirada() {
      this.$bvModal.hide("modal-caixa-retirada");
    },
    async onSangria() {
      await this.$refs.caixaInformacoes.buscarCaixa();
      this.closeSangria();
    },
    openSangria() {
      this.id_sangria = null;
      this.$bvModal.show("modal-caixa-sangria");
    },
    closeSangria() {
      this.$bvModal.hide("modal-caixa-sangria");
    },
    openVenda() {
      this.$bvModal.show("modal-caixa-venda");
    },
    closeVenda() {
      this.$bvModal.hide("modal-caixa-venda");
    },
    async openVendaConcluida(venda) {
      console.log(venda);
      await this.carregarListagem();
      let fnd = this.listagemRaw.lancamentos.find((x) =>
        x.lista.some((y) => y.id === venda)
      );
      if (fnd) {
        let vnd = fnd.lista.find((x) => x.id == venda);
        if (vnd) {
          this.openDetail(vnd);
        }
      }
      this.$bvModal.hide("modal-caixa-venda");

      console.log("encontrou", fnd, this.listagemRaw);
    },
    async cancelarRetirada(id) {
      const { value: motivo } = await this.$swal.fire({
        title: "Motivo do Cancelamento",
        input: "text",
        inputPlaceholder: "Motivo",
      });
      try {
        await CaixasLib.cancelarRetirada(id, { cancelado_motivo: motivo });
        await this.$refs.caixaInformacoes.buscarCaixa();
      } catch (error) {
        console.log(error);
      }
    },
    editarRetirada(id) {
      this.id_retirada = id;
      this.$bvModal.show("modal-caixa-retirada");
    },
    async cancelarSangria(id) {
      const { value: motivo } = await this.$swal.fire({
        title: "Motivo do Cancelamento",
        input: "text",
        inputPlaceholder: "Motivo",
      });
      try {
        await CaixasLib.cancelarSangria(id, { cancelado_motivo: motivo });
        await this.$refs.caixaInformacoes.buscarCaixa();
      } catch (error) {
        console.log(error);
      }
    },
    editarSangria(id) {
      console.log("on editar", id);
      this.id_sangria = id;
      this.$bvModal.show("modal-caixa-sangria");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/caixa.scss";
.caixa {
  .barra {
    background-color: #f5f5f5;
  }
  .barraEscura {
    background-color: #ebebeb;
  }
  .conteudo {
    background-color: #e9e8e8;
  }
  .cancelado {
    background-color: #f9f9f9;
    color: #666;
    opacity: 0.6;
  }
  .btnCaixa {
    // border: 3px solid #f9f9f9;
    border: none;
    background: transparent;
    color: #666;
    height: 100px;
    width: 100px;
    border-radius: 100px !important;
    margin: 3px;
    font-size: 12px;
    transition: 0.2s all ease;
    i {
      font-size: 30px;
      margin-bottom: 3px;
    }

    &:hover {
      // width: 120px;
      // height: 120px;
      background: #ccc;
      // color:white;
      font-size: 14px;

      // border-width: 10px;
      box-shadow: 0px 0px 4px #00000080;
    }
  }
}
.filtros {
  font-size: 10px;
  input {
    font-size: 10px;
  }
  .custom-select {
    font-size: 10px;
  }
}
</style>
