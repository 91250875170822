<template>
  <div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <b-form-group label="Data">
          <date-picker
            style="width: 100%; height: 100%"
            v-model="dataSelecionada"
            valueType="format"
            :format="'DD/MM/YYYY'"
            :clearable="false"
          ></date-picker>
        </b-form-group>
      </div>
      <div class="col-12 col-sm-6">
        <b-form-group label="Caixa Nº">
          <b-spinbutton min="1" v-model="caixaNum" />
        </b-form-group>
      </div>
      <div class="col-12">
        <select-empresa-clinica clinicaInicial :autoBlock="false" :comClinica="false" @empresa="selectEmpresa" />
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-right">
        <b-btn variant="primary" @click="openRelat()" :disabled="loadingPrint">
          <span v-if="loadingPrint">
            <b-spinner small size="sm" /> carregando...
          </span>
          <span v-else> <i class="fa fa-print" /> Abrir </span>
        </b-btn>
        <b-btn variant="secondary"  @click="$emit('close')"><i class="fa fa-ban" /> Cancelar</b-btn>
      </div>
    </div>
    <relatorio-lancamentos
      :caixaControle="caixaControle"
      v-if="print"
      @done="donePrint"
      @close="closePrint"
    />
  </div>
</template>

<script>
import SelectEmpresaClinica from "../../common/SelectEmpresaClinica.vue";
import moment from "moment";
import RelatorioLancamentos from "../Relatorios/RelatorioLancamentos.vue";
import CaixaLib from "../../../libs/CaixasLib";
export default {
  components: {
    SelectEmpresaClinica,
    RelatorioLancamentos,
  },
  props: {},
  mounted() {},
  data() {
    return {
      caixaNum: 1,
      dataSelecionada: moment().format("DD/MM/YYYY"),
      empresaSelecionada: this.$store.state.empresa,
      print: false,
      loadingPrint: false,
      caixaControle:null
    };
  },
  watch: {},
  computed: {},
  methods: {
    closePrint() {
      this.print = false;
    },
    donePrint() {
      this.loadingPrint = false;
    },
    selectEmpresa(empresa) {
      this.empresaSelecionada = empresa;
    },
    async openRelat() {
      this.loadingPrint = true;

      let caixaCont = await CaixaLib.buscarCaixaControle(
        this.empresaSelecionada.id,
        moment(this.dataSelecionada,'DD/MM/YYYY').format("YYYY-MM-DD"),
        this.caixaNum
      );

      // console.log(
      //   "caixacont",
      //   caixaCont,
      //   "re",
      //   this.empresaSelecionada.id,
      //   this.dataSelecionada,
      //   this.caixaNum
      // );
      if (caixaCont && caixaCont.length > 0) {
        this.caixaControle = caixaCont;
        await this.$forceUpdate();
        await this.$nextTick();
        setTimeout(async () => {
          this.print = true;
          await this.$forceUpdate();
          await this.$nextTick();
        }, 200);
      }else{
        this.loadingPrint = false;
        this.$swal('Atenção','Nenhum caixa aberto com os paramentros informados','error');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>