var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-2 mt-2 pr-1"},[_c('b-select',{attrs:{"options":_vm.tps,"text-field":"label"},model:{value:(_vm.findType),callback:function ($$v) {_vm.findType=$$v},expression:"findType"}})],1),_c('div',{staticClass:"col-12 col-sm-8 mt-2 pl-0 pr-1"},[_c('b-input',{attrs:{"name":"idFinde","id":"idFinde"},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.buscar.apply(null, arguments)}},model:{value:(_vm.findId),callback:function ($$v) {_vm.findId=$$v},expression:"findId"}})],1),_c('div',{staticClass:"col-12 col-sm-2 mt-2 pl-0"},[_c('b-btn',{attrs:{"variant":"primary","disabled":_vm.loading,"block":""},on:{"click":_vm.buscar}},[(!_vm.loading)?_c('b-icon-search'):_vm._e(),(_vm.loading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Buscar ")],1)],1)]),(_vm.loading)?_c('div',{staticClass:"text-center p-5"},[_c('b-spinner'),_vm._v(" "),_c('br'),_vm._v(" aguarde... ")],1):_vm._e(),(_vm.lista && _vm.lista.length > 0)?_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-12"},[_c('b-table',{ref:"tb",attrs:{"items":_vm.lista,"hover":"","selectable":"","select-mode":"single","fields":[
          { key: 'id', label: 'Código' },
          { key: 'data', label: 'Data' },
          { key: 'emp', label: 'Clínica' },
          { key: 'cliente', label: 'Cliente' },
          { key: 'vl_total', label: 'Valor' },
          { key: 'cancelado', label: 'Cancelado', class:'text-center' },
        ]},on:{"row-selected":_vm.openVenda},scopedSlots:_vm._u([{key:"cell(data)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("moment")(row.item.data,"DD/MM/YYYY"))+" ")]}},{key:"cell(vl_total)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("currency")(row.item.vl_total))+" ")]}},{key:"cell(cancelado)",fn:function(row){return [(row.item.cancelado === 1)?_c('span',{staticStyle:{"font-size":"27 px","color":"red","padding":"0px"}},[_c('b-icon-x')],1):_vm._e()]}}],null,false,2631318811)})],1)]):_vm._e(),_c('b-modal',{attrs:{"id":"modal-venda-detail","size":"lg","title":"Detalhes de venda","hide-footer":"","no-enforce-focus":""},on:{"hide":_vm.closeVendaDetail}},[(_vm.vendaEdit)?_c('venda-detail',{ref:"vendaDetails",attrs:{"vendaId":_vm.vendaEdit},on:{"close":_vm.closeVendaDetail}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }