<template>
  <div>
    <div class="row">
      <div class="col-12 col-sm-2 mt-2 pr-1">
        <b-select :options="tps" text-field="label" v-model="findType" />
      </div>
      <div class="col-12 col-sm-8 mt-2 pl-0 pr-1">
        <b-input
          v-model="findId"
          name="idFinde"
          id="idFinde"
          @keypress.enter="buscar"
        />
      </div>
      <div class="col-12 col-sm-2 mt-2 pl-0">
        <b-btn variant="primary" :disabled="loading" block @click="buscar">
          <b-icon-search v-if="!loading" />
          <b-spinner small v-if="loading" />
          Buscar
        </b-btn>
      </div>
    </div>
    <div class="text-center p-5" v-if="loading">
      <b-spinner /> <br />
      aguarde...
    </div>
    <div class="row mt-4" v-if="lista && lista.length > 0">
      <div class="col-12">
        <b-table
          :items="lista"
          hover
          selectable
          select-mode="single"
          @row-selected="openVenda"
          ref="tb"
          :fields="[
            { key: 'id', label: 'Código' },
            { key: 'data', label: 'Data' },
            { key: 'emp', label: 'Clínica' },
            { key: 'cliente', label: 'Cliente' },
            { key: 'vl_total', label: 'Valor' },
            { key: 'cancelado', label: 'Cancelado', class:'text-center' },
          ]"
        >
          <template #cell(data)="row">
            {{ row.item.data | moment("DD/MM/YYYY") }}
          </template>
          <template #cell(vl_total)="row">
            {{ row.item.vl_total | currency }}
          </template>
          <template #cell(cancelado)="row">
            <span
              v-if="row.item.cancelado === 1"
              style="font-size:27 px; color:red; padding:0px"
              ><b-icon-x
            /></span>
          </template>
        </b-table>
      </div>
    </div>
    <b-modal
      id="modal-venda-detail"
      size="lg"
      title="Detalhes de venda"
      hide-footer
      no-enforce-focus
      @hide="closeVendaDetail"
    >
      <venda-detail
        v-if="vendaEdit"
        :vendaId="vendaEdit"
        @close="closeVendaDetail"
        ref="vendaDetails"
      />
    </b-modal>
  </div>
</template>

<script>
import VendaLib from "../../../libs/VendaLib";
import VendaDetail from "./VendaDetail.vue";
let tps = [
  { value: "venda_id", label: "ID Venda" },
  { value: "contas_receber_id", label: "ID C.R." },
  { value: "pagamento_id", label: "ID Pagamento" },
  { value: "autorizacao", label: "Autorização" },
  { value: "documento", label: "CV" },
];
export default {
  components: { VendaDetail },
  props: {},
  mounted() {},
  data() {
    return {
      tps: tps,
      findType: "venda_id",
      findId: "",
      loading: false,
      lista: [],
      vendaEdit: null,
    };
  },
  watch: {},
  computed: {},
  methods: {
    closeVendaDetail() {
      this.vendaEdit = null;
      this.$bvModal.hide("modal-venda-detail");
    },
    openVenda(rows) {
      console.log(rows);
      if (rows && rows.length > 0) {
        this.vendaEdit = rows[0].id;
        this.$bvModal.show("modal-venda-detail");
      }
      this.$refs.tb.clearSelected();
    },
    async buscar() {
      this.loading = true;
      this.lista = [];
      if (!this.findId || this.findId.trim() == "") {
        this.$swal("Erro", "Informe algum valor para buscar!", "error");
      } else {
        let res = await VendaLib.buscarPorID(this.findType, this.findId);
        console.log("res", res);

        if (res && res.success && res.data && res.data.length > 0) {
          this.lista = res.data.map((it) => ({
            ...it,
            _rowVariant: it.cancelado === 1 ? "danger" : "",
          }));
        }
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
