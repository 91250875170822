<template>
  <div>
      <div class="row">
          <div class="col-12">
              <b-form-group label="Data">
                  <b-datepicker />
              </b-form-group>
          </div>
          <div class="col-12">
              <b-form-group label="Caixa Nº">
                  <b-spinbutton min="1" v-model="caixaNum" />
              </b-form-group>
          </div>          
          <div class="col-12">
              <b-form-group label="Empresa / Clínica">
                  <b-select />
              </b-form-group>
          </div>
      </div>
  </div>
</template>

<script>
export default {
 components:{

},
 props:{

 },
 mounted(){

 },
 data(){ 
    return {
        caixaNum:1
    }
 },
 watch:{

 },
 computed:{

 },
 methods:{

 }
}
</script>

<style lang="scss" scoped>

</style>