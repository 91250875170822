<template>
  <div style="font-size: 12px" class="fechamento">
    <div class="text-center" v-if="loading">
      <b-spinner /><br />
      aguarde...
    </div>
    <div class="row" v-if="!loading">
      <div class="col-12">
        <table class="table" style="font-size: 12px">
          <tr>
            <th><i class="far fa-calendar" /> Data Aberto</th>
            <td>
              {{ caixaControle.dt_abertura | moment("DD/MM/YYYY HH:mm") }}
            </td>
            <th><b-icon-shop /> Empresa/Clínica</th>
            <td>{{ empresa.fantasia }} - {{ empresa.sigla }}</td>
          </tr>

          <tr>
            <th><b-icon-person-badge /> Usuário Abriu</th>
            <td colspan="">{{ caixaControle.usuarioAbriu }}</td>
            <th><b-icon-cash /> Valor Abertura</th>
            <td>{{ caixaControle.vl_abertura | currency }}</td>
          </tr>
        </table>

        <div class="row">
          <div class="col-12 col-sm-6">
            <table class="table table-hover table-striped">
              <thead>
                <tr>
                  <th>Forma de Pagamento</th>
                  <th class="text-center">Qtd.</th>
                  <th class="text-center">Valor</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="forma in fechamento.formas"
                  :key="`pgto_${forma.forma}`"
                  class="cursor"
                  @click="detailForma(forma)"
                >
                  <td>{{ forma.forma }}</td>
                  <td class="text-center">{{ forma.lista.length }}</td>
                  <td class="text-center">{{ forma.valor | currency }}</td>
                </tr>
                <tr>
                  <small>
                    <i>
                      <b-icon-receipt-cutoff /> clique para detalhes os
                      lançamentos</i
                    ></small
                  >
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-12 col-sm-6">
            <caixa-fechamento-resumo
              :caixaControle="caixaControle"
              :clinica="empresa"
              :fechamento="fechamento"
              :totais="totais"
            />
          </div>
        </div>
      </div>
      <div class="col-12 text-center mt-3">
        <button
          class="btn btn-warning"
          @click="imprimir"
          :disabled="loadingPrint"
          v-if="
            caixaControle.dt_fechamento && caixaControle.dt_fechamento != ''
          "
        >
          <span v-if="loadingPrint">
            <b-spinner size="xs" small />
          </span>
          <b-icon-printer v-if="!loadingPrint" /> Imprimir Conferência
        </button>

        <button
          v-if="!caixaControle.dt_fechamento"
          class="btn btn-danger"
          @click="fecharCaixa"
        >
          <b-icon-inboxes /> Fechar Caixa
        </button>
      </div>
    </div>
    <b-modal
      id="modal-print"
      v-if="printNow"
      hide-footer
      hide-header
      @hide="donePrint"
    >
      <small>clique fora para fechar</small>
      <print-component :printNow="printNow" @done="donePrint">
        <caixa-fechamento-resumo
          :caixaControle="caixaControle"
          :fechamento="fechamento"
          :clinica="empresa"
          :totais="totais"
          @hide="printNow = false"
        />
      </print-component>
    </b-modal>
    <b-modal
      id="modal-forma-detail"
      hide-footer
      title="Detalhamento de Formas de Pagamento"
    >
      <div class="row" v-if="formaShow">
        <div class="col-12">
          <h2>{{ formaShow.forma }}</h2>
        </div>
        <div class="col-12">
          <table class="table table-sm">
            <thead>
              <th>Detalhes</th>
              <th class="text-center">Valor</th>
            </thead>
            <tbody>
              <tr
                v-for="detalhe in formaShow.lista"
                :key="`detalhe_op_${detalhe.forma}`"
              >
                <td>
                  <span
                    style="font-size: 10px"
                    v-if="detalhe.venda"
                    v-html="
                      `<b>Venda: ${detalhe.venda.id} | Cliente: ${detalhe.venda.cliente}</b><br/>`
                    "
                  ></span>
                  <small
                    v-if="
                      detalhe.dados_pagamento && detalhe.dados_pagamento.pix
                    "
                  >
                    <b>Identificador: </b>
                    {{ detalhe.dados_pagamento.pix.txId }} <b> Status: </b>
                    {{ detalhe.dados_pagamento.pix.status }}
                  </small>
                  <span
                    v-if="detalhe.documento"
                    v-html="' <b>Doc.:</b> ' + detalhe.documento"
                  ></span>
                  <span
                    v-if="detalhe.autorizacao"
                    v-html="' <b>Aut.:</b> ' + detalhe.autorizacao"
                  ></span>
                  <span
                    v-if="
                      detalhe.dados_pagamento &&
                      detalhe.dados_pagamento.bandeira &&
                      detalhe.dados_pagamento.bandeira.nome
                    "
                    v-html="
                      ' <b>Bandeira:</b> ' +
                      detalhe.dados_pagamento.bandeira.nome
                    "
                  ></span>
                  <span
                    v-if="
                      detalhe.dados_pagamento && detalhe.dados_pagamento.vale
                    "
                    v-html="' <b>Vale: </b> ' + detalhe.dados_pagamento.vale"
                  ></span>
                  <span v-if="detalhe.entidade && detalhe.entidade">
                    <b>Entidade: </b> {{ detalhe.entidade }}
                  </span>
                </td>
                <td class="text-center">
                  {{ detalhe.valor | currency }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import CaixaLib from "../../libs/CaixasLib";
import EmpresaLib from "../../libs/EmpresasLib";
import PrintComponent from "../Print/PrintComponent.vue";
import CaixaFechamentoResumo from "./CaixaFechamentoResumo.vue";
import moment from "moment";
import CaixasLib from "../../libs/CaixasLib";
export default {
  components: { CaixaFechamentoResumo, PrintComponent },
  props: {
    caixaControle: Object,
  },
  mounted() {
    this.carregarFechamento();
  },
  data() {
    return {
      fechamento: {},
      loading: true,
      empresas: null,
      empresa: null,
      totais: {
        totalDinheiro: 0,
        totalDinheiroTotal: 0,
        totalEmMaos: 0,
        totalEmMaosSSangria: 0,
        totalDevolucoes:0,
      },
      printNow: false,
      loadingPrint: false,
      formaShow: null,
      receitasNaoLancadas: [],
    };
  },
  watch: {
    caixaControle: {
      deep: true,
      handler() {
        this.carregarFechamento();
      },
    },
  },
  computed: {},
  methods: {
    fecharCaixa() {
      // console.log("fech", this.empresa);
      // if (this.printNow == this.printNow) {
      //   return;
      // }

      if (this.receitasNaoLancadas.length > 0) {
        this.$swal({
          title: "Receitas não lançadas!",
          html:
            "Exite horarios da agenda de atendimento sem receita lançada: <hr/>" +
            this.receitasNaoLancadas.map((it) => it.paciente).join("<hr/>"),
          icon: "error",
        });
        return;
      }

      let semNota = false;
      for (let p of this.fechamento.formas) {
        if (p.forma == "CARTAO" || p.forma == "PIX") {
          for (let v of p.lista) {
            if (!semNota && (!v.idNota || v.idNota == null)) {
              semNota = true;
            }
          }
        }
      }

      if (
        semNota &&
        this.empresa &&
        this.empresa.configuracoes &&
        this.empresa.configuracoes.exigirEmissaoNFFechamentoCaixa &&
        this.empresa.configuracoes.exigirEmissaoNFFechamentoCaixa == "1"
      ) {
        this.$swal(
          "Atenção",
          "Você precisa emitir todas as notas com as formas de pagamento Cartão e PIX antes de fechar o caixa!",
          "error"
        );
        return;
      }

      this.$swal({
        title: "Você tem cereza?",
        text: "Tem certeza que deseja fechar o caixa?",
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
      }).then(async (x) => {
        if (x.isConfirmed) {
          let obj = {
            user_fechamento_id: this.$store.state.auth.user.id,
            id: this.caixaControle.id,
            dt_fechamento: moment().format("YYYY-MM-DD HH:mm:ss"),
          };

          let result = await CaixaLib.registraControle(obj);

          this.$swal({
            title: result
              ? "Caixa " + "fechado" + " com sucesso!"
              : "Erro ao realizar a transação de caixa",
            toast: true,
            position: "top-end",
            timer: 2200,
            icon: result ? "success" : "error",
            showConfirmButton: false,
          });
          if (result) {
            this.caixaControle.dt_fechamento = moment("YYYY-MM-DD HH:mm:ss");
          }

          this.carregarFechamento();
        }
      });
    },
    detailForma(forma) {
      this.formaShow = forma;
      this.$bvModal.show("modal-forma-detail");
    },
    donePrint() {
      this.loadingPrint = false;
    },
    async imprimir() {
      this.loadingPrint = true;
      await this.$forceUpdate();
      await this.$nextTick();
      setTimeout(async () => {
        this.printNow = true;
        await this.$forceUpdate();
        await this.$nextTick();
        this.$bvModal.show("modal-print");
        document
          .getElementsByClassName("pdf-preview")[0]
          .childNodes[0].addEventListener("click", function () {
            this.$bvModal.hide("modal-print");
          });
      }, 200);
    },
    async carregarFechamento() {
      this.receitasNaoLancadas = [];
      this.loading = true;
      try {
        this.empresas = await EmpresaLib.getEmpresasGrupo();
        this.empresa = this.empresas.find(
          (x) => x.id === this.caixaControle.empresa_id
        );

        this.fechamento = await CaixaLib.fechamentoCaixa(this.caixaControle);
        // console.log("FECHAMENTO",this.fechamento);
        this.totais.totalDinheiro = this.fechamento.formas.reduce((ret, vl) => {
          if (vl.forma == "DINHEIRO") {
            ret += vl.valor;
          }
          return ret;
        }, 0);

        let totalRetiradas = await this.fechamento.retiradas.reduce(
          (ret, vl) => {
            return (ret += vl.valor);
          },
          0
        );
        console.log("devvvs", this.fechamento.devolucoes);
        let totalDevolucoes = await this.fechamento.devolucoes
          .filter((x) => x.tipo_devolucao == "Devolução Dinheiro")
          .reduce((ret, vl) => {
            return (ret += vl.vl_estorno);
          }, 0);

        console.log("TOTAL DEV", totalDevolucoes);
        let totalSangrias = await this.fechamento.sangrias.reduce((ret, vl) => {
          return (ret += vl.valor);
        }, 0);
        this.totais.totalDevolucoes = totalDevolucoes;
        this.totais.totalDinheiroTotal =
          this.totais.totalDinheiro +
          (this.caixaControle.vl_abertura - totalRetiradas - totalDevolucoes);
        this.totais.totalDinheiroEmMaos =
          this.totais.totalDinheiro +
          (this.caixaControle.vl_abertura -
            totalRetiradas -
            totalDevolucoes -
            totalSangrias);
        this.totais.totalEmMaosSSangria =
          this.totais.totalDinheiro -
          totalRetiradas -
          totalSangrias -
          totalDevolucoes;

        console.log("fechamento", this.fechamento, this.caixaControle);

        let receitasNaoLancadas = await CaixasLib.verificarReceitasNaoLancadas({
          empresa_id: this.caixaControle.empresa_id,
          data: moment(this.caixaControle.dt_caixa).format("YYYY-MM-DD"),
        });

        if (receitasNaoLancadas) {
          console.log("receitasNaoLancadas", receitasNaoLancadas);
          this.receitasNaoLancadas = receitasNaoLancadas.data;
        }
      } catch (e) {
        console.log("erro fechamento ", e);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.cursor {
  cursor: pointer;
}
.fechamento {
  table {
    border: none;
    th {
      border: none;
      background-color: #ececec;
      color: #777;
    }
    tr {
      border: none;
      border-bottom: 0.05em solid #dedede;
    }
    td {
      border: none;
    }
  }
  .resumo {
    background-color: rgb(240, 238, 126);
    padding: 20px;
    table {
      th,
      td {
        background: none;
        padding: 0px;
        padding-top: 4px;
      }
      tr {
        border-bottom: 1px dotted #777;
      }
      td {
        font-weight: 400;

        color: #777;
      }
    }
  }
}
</style>
