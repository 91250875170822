var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3"},[_c('label',[_vm._v("Data")]),_c('br'),(_vm.user.perfil != 'admin')?_c('span',[_vm._v(_vm._s(_vm._f("moment")(_vm.data,"DD/MM/YYYY")))]):_vm._e(),(_vm.user.perfil == 'admin')?_c('b-input',{attrs:{"type":"date","readonly":_vm.loading},model:{value:(_vm.dataSelecionada),callback:function ($$v) {_vm.dataSelecionada=$$v},expression:"dataSelecionada"}}):_vm._e()],1),_c('div',{staticClass:"col-6"},[(_vm.user.perfil != 'admin')?_c('span',[_vm._v(_vm._s(_vm.empresa.sigla))]):_vm._e(),(_vm.user.perfil == 'admin')?_c('select-empresa-clinica',{attrs:{"clinicaInicial":"","comClinica":false,"empresa":_vm.empresaSelecionada,"readonly":_vm.loading},on:{"empresa":_vm.selectEmpresa}}):_vm._e()],1),_c('div',{staticClass:"col-3"},[(_vm.user.perfil == 'admin')?_c('b-btn',{staticStyle:{"margin-top":"25px"},attrs:{"block":"","variant":"primary"},on:{"click":_vm.openGerarAcertos}},[_c('b-icon-toggles2'),_vm._v(" Gerar Acerto")],1):_vm._e()],1)]),(_vm.loading)?_c('div',{staticClass:"text-center p-5"},[_c('b-spinner')],1):_vm._e(),(!_vm.loading)?_c('b-table',{attrs:{"items":_vm.lista,"fields":[
      { key: 'Medico', label: 'Médico' },
      { key: 'qtd', label: 'Qtd.', class: 'text-center' },
      { key: 'vl_custo', label: 'Valor', class: 'text-center' },
      { key: 'total', label: 'Total', class: 'text-center' },
      { key: 'opc', label: '', class: 'text-center' },
    ],"empty-text":`Nenhum acerto encontrado`,"hover":"","show-empty":!_vm.lista || _vm.lista.length <= 0},scopedSlots:_vm._u([{key:"cell(vl_custo)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("currency")(row.item.vl_custo))+" ")]}},{key:"cell(total)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("currency")(row.item.total))+" ")]}},{key:"cell(opc)",fn:function(row){return [_c('div',[_c('b-btn',{attrs:{"variant":"none","type":"button"},on:{"click":function($event){return _vm.cancelar(row.item.venda_id)}}},[_c('b-icon-trash')],1)],1)]}}],null,false,984107974)}):_vm._e(),((!_vm.lista || _vm.lista.length <= 0) && _vm.user.perfil == 'admin' && !_vm.loading)?_c('div',{staticClass:"text-center"},[_c('b-btn',{attrs:{"variant":"primary"},on:{"click":_vm.openGerarAcertos}},[_c('b-icon-toggles2'),_vm._v(" Gerar Acerto")],1)],1):_vm._e(),_c('hr'),_c('div',{staticClass:"text-right"},[_c('b-btn',{attrs:{"variant":"secondary","size":"lg","type":"button"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('b-icon-door-closed'),_vm._v(" Fechar")],1)],1),_c('b-modal',{attrs:{"id":"modal-gerar-acertos","hide-footer":"","title":"Gerar Acerto","no-close-on-backdrop":"","no-close-on-esc":"","no-enforce-focus":""}},[_c('acertos-form',{attrs:{"empresa":_vm.empresa,"data":_vm.data},on:{"close":_vm.closeGerarAcertos}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }