var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fechamento",staticStyle:{"font-size":"12px"}},[(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('b-spinner'),_c('br'),_vm._v(" aguarde... ")],1):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('table',{staticClass:"table",staticStyle:{"font-size":"12px"}},[_c('tr',[_vm._m(0),_c('td',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.caixaControle.dt_abertura,"DD/MM/YYYY HH:mm"))+" ")]),_c('th',[_c('b-icon-shop'),_vm._v(" Empresa/Clínica")],1),_c('td',[_vm._v(_vm._s(_vm.empresa.fantasia)+" - "+_vm._s(_vm.empresa.sigla))])]),_c('tr',[_c('th',[_c('b-icon-person-badge'),_vm._v(" Usuário Abriu")],1),_c('td',{attrs:{"colspan":""}},[_vm._v(_vm._s(_vm.caixaControle.usuarioAbriu))]),_c('th',[_c('b-icon-cash'),_vm._v(" Valor Abertura")],1),_c('td',[_vm._v(_vm._s(_vm._f("currency")(_vm.caixaControle.vl_abertura)))])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-6"},[_c('table',{staticClass:"table table-hover table-striped"},[_vm._m(1),_c('tbody',[_vm._l((_vm.fechamento.formas),function(forma){return _c('tr',{key:`pgto_${forma.forma}`,staticClass:"cursor",on:{"click":function($event){return _vm.detailForma(forma)}}},[_c('td',[_vm._v(_vm._s(forma.forma))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(forma.lista.length))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._f("currency")(forma.valor)))])])}),_c('tr',[_c('small',[_c('i',[_c('b-icon-receipt-cutoff'),_vm._v(" clique para detalhes os lançamentos")],1)])])],2)])]),_c('div',{staticClass:"col-12 col-sm-6"},[_c('caixa-fechamento-resumo',{attrs:{"caixaControle":_vm.caixaControle,"clinica":_vm.empresa,"fechamento":_vm.fechamento,"totais":_vm.totais}})],1)])]),_c('div',{staticClass:"col-12 text-center mt-3"},[(
          _vm.caixaControle.dt_fechamento && _vm.caixaControle.dt_fechamento != ''
        )?_c('button',{staticClass:"btn btn-warning",attrs:{"disabled":_vm.loadingPrint},on:{"click":_vm.imprimir}},[(_vm.loadingPrint)?_c('span',[_c('b-spinner',{attrs:{"size":"xs","small":""}})],1):_vm._e(),(!_vm.loadingPrint)?_c('b-icon-printer'):_vm._e(),_vm._v(" Imprimir Conferência ")],1):_vm._e(),(!_vm.caixaControle.dt_fechamento)?_c('button',{staticClass:"btn btn-danger",on:{"click":_vm.fecharCaixa}},[_c('b-icon-inboxes'),_vm._v(" Fechar Caixa ")],1):_vm._e()])]):_vm._e(),(_vm.printNow)?_c('b-modal',{attrs:{"id":"modal-print","hide-footer":"","hide-header":""},on:{"hide":_vm.donePrint}},[_c('small',[_vm._v("clique fora para fechar")]),_c('print-component',{attrs:{"printNow":_vm.printNow},on:{"done":_vm.donePrint}},[_c('caixa-fechamento-resumo',{attrs:{"caixaControle":_vm.caixaControle,"fechamento":_vm.fechamento,"clinica":_vm.empresa,"totais":_vm.totais},on:{"hide":function($event){_vm.printNow = false}}})],1)],1):_vm._e(),_c('b-modal',{attrs:{"id":"modal-forma-detail","hide-footer":"","title":"Detalhamento de Formas de Pagamento"}},[(_vm.formaShow)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h2',[_vm._v(_vm._s(_vm.formaShow.forma))])]),_c('div',{staticClass:"col-12"},[_c('table',{staticClass:"table table-sm"},[_c('thead',[_c('th',[_vm._v("Detalhes")]),_c('th',{staticClass:"text-center"},[_vm._v("Valor")])]),_c('tbody',_vm._l((_vm.formaShow.lista),function(detalhe){return _c('tr',{key:`detalhe_op_${detalhe.forma}`},[_c('td',[(detalhe.venda)?_c('span',{staticStyle:{"font-size":"10px"},domProps:{"innerHTML":_vm._s(
                    `<b>Venda: ${detalhe.venda.id} | Cliente: ${detalhe.venda.cliente}</b><br/>`
                  )}}):_vm._e(),(
                    detalhe.dados_pagamento && detalhe.dados_pagamento.pix
                  )?_c('small',[_c('b',[_vm._v("Identificador: ")]),_vm._v(" "+_vm._s(detalhe.dados_pagamento.pix.txId)+" "),_c('b',[_vm._v(" Status: ")]),_vm._v(" "+_vm._s(detalhe.dados_pagamento.pix.status)+" ")]):_vm._e(),(detalhe.documento)?_c('span',{domProps:{"innerHTML":_vm._s(' <b>Doc.:</b> ' + detalhe.documento)}}):_vm._e(),(detalhe.autorizacao)?_c('span',{domProps:{"innerHTML":_vm._s(' <b>Aut.:</b> ' + detalhe.autorizacao)}}):_vm._e(),(
                    detalhe.dados_pagamento &&
                    detalhe.dados_pagamento.bandeira &&
                    detalhe.dados_pagamento.bandeira.nome
                  )?_c('span',{domProps:{"innerHTML":_vm._s(
                    ' <b>Bandeira:</b> ' +
                    detalhe.dados_pagamento.bandeira.nome
                  )}}):_vm._e(),(
                    detalhe.dados_pagamento && detalhe.dados_pagamento.vale
                  )?_c('span',{domProps:{"innerHTML":_vm._s(' <b>Vale: </b> ' + detalhe.dados_pagamento.vale)}}):_vm._e(),(detalhe.entidade && detalhe.entidade)?_c('span',[_c('b',[_vm._v("Entidade: ")]),_vm._v(" "+_vm._s(detalhe.entidade)+" ")]):_vm._e()]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("currency")(detalhe.valor))+" ")])])}),0)])])]):_vm._e()])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('i',{staticClass:"far fa-calendar"}),_vm._v(" Data Aberto")])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Forma de Pagamento")]),_c('th',{staticClass:"text-center"},[_vm._v("Qtd.")]),_c('th',{staticClass:"text-center"},[_vm._v("Valor")])])])
}]

export { render, staticRenderFns }