<template>
  <div class="resumo">
    <h4 class="text-center"><b-icon-receipt /> Resumo</h4>
    <hr />
    <div class="row">
      <div class="col-6 text-nowrap">
        <label> Clínica: </label>
        {{ clinica.fantasia }} - {{ clinica.sigla }}
      </div>
      <div class="col-6">
        <label> Data: </label>
        {{ caixaControle.dt_abertura | moment("DD/MM/YYYY HH:mm") }}
      </div>
      
      <div class="col-12 text-center" >
        <div v-if="caixaControle.dt_fechamento && caixaControle.dt_fechamento!=''">
        <label> Fechamento: </label>
        {{ caixaControle.dt_fechamento | moment("DD/MM/YYYY HH:mm") }}
        </div>
        <div v-else>
          Caixa não fechado ainda
        </div>
      </div>
    </div>
    <table class="table">
      <tr>
        <th>Valor Aberto</th>
        <td class="text-right">
          {{ caixaControle.vl_abertura | currency }}
        </td>
      </tr>
      <!--------ENTRADAS------->
      <tr>
        <th colspan="2" class="text-center">
          <b>Entradas</b>
        </th>
      </tr>
      <tr v-for="forma in fechamento.formas" :key="`pgto12_${forma.forma}`">
        <th class="pl-2">{{ forma.forma }}</th>
        <td class="text-right">{{ forma.valor | currency }}</td>
      </tr>

      <!-------RETIRADAS------->
      <tr>
        <th
          colspan="2"
          class="text-center"
          v-if="fechamento.retiradas && fechamento.retiradas.length > 0"
        >
          <b>Retiradas</b>
        </th>
      </tr>
      <tr
        v-for="retirada in fechamento.retiradas"
        :key="`retirada_${retirada.id}`"
      >
        <th>{{ retirada.motivo }}</th>
        <td class="text-right">{{ retirada.valor | currency }}</td>
      </tr>

      <!-------SANGRIAS------->
      <tr>
        <th
          colspan="2"
          class="text-center"
          v-if="fechamento.sangrias && fechamento.sangrias.length > 0"
        >
          <b>Sangrias</b>
        </th>
      </tr>
      <tr
        v-for="sangria in fechamento.sangrias"
        :key="`retirada_${sangria.id}`"
      >
        <th>{{ sangria.motivo }}</th>
        <td class="text-right">{{ sangria.valor | currency }}</td>
      </tr>

      <!-------TOTAIS------->
      <tr>
        <th colspan="2" class="text-center pt-5">
          <b>Totalizador</b>
        </th>
      </tr>
      <tr>
        <th>Total Dinheiro</th>
        <td class="text-right">{{ totais.totalDinheiro | currency }}</td>
      </tr>
      <tr>
        <th>Total Devoluções</th>
        <td class="text-right">- {{ totais.totalDevolucoes | currency }}</td>
      </tr>
      <tr>
        <th>Total Dinheiro + Abertura - Retiradas - Devoluções</th>
        <td class="text-right">{{ totais.totalDinheiroTotal | currency }}</td>
      </tr>
      <tr>
        <th>Total Dinheiro Em Mãos</th>
        <td class="text-right">
          {{ totais.totalDinheiroEmMaos | currency }}
        </td>
      </tr>
      <tr>
        <th>Total Dinheiro Em Mãos - Abertura </th>
        <td class="text-right">
          {{ totais.totalEmMaosSSangria | currency }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
// import PrintComponent from "../Print/PrintComponent.vue";
export default {
  components: {},
  props: {
    totais: Object,
    fechamento: Object,
    caixaControle: Object,
    clinica: Object,
  },
  mounted() {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.resumo {
  background-color: rgb(240, 238, 126);
  padding: 20px;
  max-width: 400px;
  margin: 0 auto;
  table {
    th,
    td {
      background: none;
      padding: 0px;
      padding-top: 4px;
    }
    tr {
      border-bottom: 1px dotted #777;
    }
    td {
      font-weight: 400;

      color: #777;
    }
  }
}
</style>
